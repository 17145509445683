import React from "react"
import { graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import SvgWave from "../../images/svgWave"

import * as style from "../featureProduct/style.module.scss"

import ProductFeatureGrid from "../../components/product/productFeatureGrid"
// Import SVGs
import { ReactComponent as SvgOne } from "./one.svg"
import { ReactComponent as SvgTwo } from "./two.svg"
import { ReactComponent as TopRight } from "@static/assets/right-top.svg"
import { ReactComponent as BottomMiddle } from "@static/assets/middle-bottom.svg"

// Import Cta Buttons
import CtaButton from "../../components/global/ctaIllustration/CtaButton"
import MyLink from "../../components/myLink"
import BlocksContent from "../../components/blocksContent"
import SanityIllustrationCTABanner from "@components/shared/SanityIllustrationCtaBanner"
import SanityIllustrationText from "@components/shared/SanityIllustrationText"

import RecentBlogsFeed from "../../components/recentBlogsFeed"
import HeroHeader from "../../components/heroHeader"
import Hero from "@components/shared/productHero"
import Link from "@components/shared/link"
import { buttonStyle } from "@util/constants"
import BlogsFeed from "../blogsFeed"
export const query = graphql`
  query($slug: String) {
    sanityPracticeAreaSingle(slug: { current: { eq: $slug } }) {
      title
      slug {
        current
      }
      seo {
        ...sanitySeo
      }
      heroIllustratedCta {
        ...sanityIllustrationCtaBanner
      }
      altFeatures {
        ... on SanityIllustrationText {
          ...sanityIllustrationText
        }
        ... on SanityIllustrationCtaBanner {
          ...sanityIllustrationCtaBanner
        }
      }
      blogReference {
        ...sanityProductBlogReference
      }
      heroHeader {
        mainContent {
          _rawColumnContent(resolveReferences: { maxDepth: 5 })
        }
        subContent {
          _rawColumnContent(resolveReferences: { maxDepth: 5 })
        }
        featureImage {
          asset {
            url
            gatsbyImageData(
              layout: FULL_WIDTH
              placeholder: NONE
              formats: [AUTO, WEBP]
            )
          }
        }
        waveColor
      }
      productHero {
        ...sanityProductHero
      }
      illustratedCta {
        ...sanityIllustrationCtaBanner
      }
    }
  }
`
// let ogImage = data.seo.ogImage
// if (ogImage) {
//   ogImage = data.seo.ogImage.asset.url
// } else {
//   ogImage = null
// }

export default ({ data }) => (
  <Layout>
    <SEO
      title={
        data.sanityPracticeAreaSingle.seo
          ? data.sanityPracticeAreaSingle.seo.pageTitle
          : null
      }
      description={
        data.sanityPracticeAreaSingle.seo
          ? data.sanityPracticeAreaSingle.seo.pageDescription
          : null
      }
      keywords={
        data.sanityPracticeAreaSingle.seo
          ? data.sanityPracticeAreaSingle.seo.pageKeywords
          : null
      }
      image={
        data.sanityPracticeAreaSingle.seo
          ? data.sanityPracticeAreaSingle.seo.ogImage
          : null
      }
      url={`/practise-area/${data.sanityPracticeAreaSingle.slug.current}`}
    />
    {/* Hero Banner */}
    {data.sanityPracticeAreaSingle.productHero ? (
      <Hero data={data.sanityPracticeAreaSingle.productHero} />
    ) : data.sanityPracticeAreaSingle.heroHeader ? (
      <HeroHeader data={data.sanityPracticeAreaSingle.heroHeader} />
    ) : (
      <div className={style.wrapper}>
        <div className={style.svgMax}>
          <SvgOne className={style.svgFirst} />
          <div className={style.heroWrapperInner}>
            <div className={style.textWrapper}>
              <div className={style.textInner}>
                <p>
                  <Link to="/practice-areas/">Practice Areas</Link> {" > "}{" "}
                  {data.sanityPracticeAreaSingle.title}
                </p>
                <h1>{data.sanityPracticeAreaSingle.title}</h1>
                <p>
                  {
                    data.sanityPracticeAreaSingle.heroIllustratedCta
                      .ctaTextIllustration.ctaText
                  }
                </p>
                <div className={style.buttonContainer}>
                  {data.sanityPracticeAreaSingle.heroIllustratedCta.ctaTextIllustration.ctaButtons.map(
                    button => {
                      return (
                        <MyLink {...button} className={style.ctaButtonCont} />
                      )
                    }
                  )}
                </div>
              </div>
            </div>
            {data.sanityPracticeAreaSingle.heroIllustratedCta &&
              data.sanityPracticeAreaSingle.heroIllustratedCta
                .ctaIllustration &&
              data.sanityPracticeAreaSingle.heroIllustratedCta.ctaIllustration
                .asset && (
                <div className={style.imgContainer}>
                  <GatsbyImage
                    image={
                      data.sanityPracticeAreaSingle.heroIllustratedCta
                        .ctaIllustration.asset.gatsbyImageData
                    }
                    className={style.heroImage}
                    alt=""
                  />
                </div>
              )}
          </div>
        </div>
      </div>
    )}

    {/* Bellow Fold */}
    {/* <SvgWave color={data.waveColor || "#FED141"} /> */}

    {data.sanityPracticeAreaSingle.illustratedCta &&
      data.sanityPracticeAreaSingle.illustratedCta.ctaIllustration &&
      data.sanityPracticeAreaSingle.illustratedCta.ctaIllustration.asset && (
        <SanityIllustrationCTABanner
          data={data.sanityPracticeAreaSingle.illustratedCta}
          displayTopSVG
        />
      )}
    {/* Alternating hero features */}
    <div className={style.wrapper}>
      <div className={style.svgMax}>
        <div className={style.wrapperInner}>
          {data.sanityPracticeAreaSingle.altFeatures.map(feature => {
            if (feature._type === "illustrationText") {
              return <SanityIllustrationText feature={feature} />
            }
            if (feature._type === "illustrationCtaBanner") {
              return <SanityIllustrationCTABanner data={feature} />
            }
          })}
        </div>
      </div>
    </div>
    <ProductFeatureGrid isPractiseArea />
    {/* News or Blog or Company Resources */}
    {data.sanityPracticeAreaSingle.blogReference ? (
      // <BlogFeed {...data.sanityPracticeAreaSingle.blogReference} />
      <BlogsFeed data={data.sanityPracticeAreaSingle.blogReference} />
    ) : (
      <RecentBlogsFeed />
    )}
  </Layout>
)
