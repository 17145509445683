// extracted by mini-css-extract-plugin
export var SvgTwo = "style-module--SvgTwo--1wTla";
export var bottomMiddle = "style-module--bottomMiddle--xNk-7";
export var button = "style-module--button---t3Ib";
export var buttonContainer = "style-module--buttonContainer--5yhCA";
export var colorBlock = "style-module--colorBlock--J+Fpb";
export var colorBlockImg = "style-module--colorBlockImg--jqYoH";
export var colorBlockWrapperInner = "style-module--colorBlockWrapperInner--ukKnr";
export var colorButtonContainer = "style-module--colorButtonContainer--GfyGc";
export var colorImgContainer = "style-module--colorImgContainer--8Meyf";
export var colorSvgMax = "style-module--colorSvgMax--rm4XG";
export var colorTextContainer = "style-module--colorTextContainer--8Gq6u";
export var featureContainer = "style-module--featureContainer--LzuKE";
export var featureImg = "style-module--featureImg--F8htT";
export var featureImgContainer = "style-module--featureImgContainer--KfNLD";
export var featureImgNoMax = "style-module--featureImgNoMax--+wrgE";
export var featureText = "style-module--featureText--4oqnn";
export var heroImage = "style-module--heroImage--Aa3J2";
export var heroWrapperInner = "style-module--heroWrapperInner--XBGJM";
export var imgContainer = "style-module--imgContainer--a-9ez";
export var isStyleH1 = "style-module--is-style-h1--c8-Vp";
export var isStyleH2 = "style-module--is-style-h2--NI+du";
export var isStyleH3 = "style-module--is-style-h3--HNLM4";
export var isStyleH4 = "style-module--is-style-h4--k+d1C";
export var isStyleH5 = "style-module--is-style-h5--tUCgk";
export var isStyleH6 = "style-module--is-style-h6--9GyKS";
export var link = "style-module--link--OmMgq";
export var svgFirst = "style-module--svgFirst---kx+R";
export var svgMax = "style-module--svgMax--QeHGe";
export var textInner = "style-module--textInner--YIAMT";
export var textWrapper = "style-module--textWrapper--zLcj8";
export var videoWrapper = "style-module--videoWrapper--hAT2d";
export var wrapper = "style-module--wrapper--28gJN";
export var wrapperInnerInfo = "style-module--wrapperInnerInfo--Cv2bT";